"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AllowedCountriesPlugin = void 0;
var browser_tracker_core_1 = require("@snowplow/browser-tracker-core");
/**
 * AllowedCountries BrowserTracker plugin
 *
 * !!! IMPORTANT NOTE !!!
 * This plugin will prevent you from using custom
 * buffer size (see: https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/tracker-setup/initialization-options/#post-support)
 * and
 * opt-out cookie (see: https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/tracker-setup/initialization-options/#opt-out-cookie)
 *
 * Calls the allowed-countries endpoint and enables tracking if compliant.
 * Clears all user data and buffered events otherwise
 */
function AllowedCountriesPlugin(config) {
    var tracker = null;
    var waitingForAllowedCountriesCheck = false;
    var lastCheckTimestamp = Date.now(); // First check is always in `activateBrowserPlugin` function
    var optOutCookie = 'lct-opt-out-ac';
    // opt-out cookie ttl is one year, but we'll check AllowedCountries endpoint
    // every 3 hours and disable the cookie if needed
    var optOutCookieTtlSeconds = 360 * 24 * 60 * 60; // 1 year
    var localStorageKey = 'lct-ac-conf';
    var allowedCountriesEndpoint = new URL('https://allowed-countries.scout.services.lego.com/');
    var maximumCacheAgeHours = 3;
    function setCache(cache) {
        try {
            localStorage.setItem(localStorageKey, JSON.stringify(cache));
        }
        catch (_a) {
            /* empty */
        }
        return cache;
    }
    function getCache() {
        try {
            var raw = localStorage.getItem(localStorageKey);
            if (!raw)
                return null;
            var cache = JSON.parse(raw);
            return cache;
        }
        catch (_a) {
            return null;
        }
    }
    function fetchAndCache() {
        if (config === null || config === void 0 ? void 0 : config.appKey) {
            allowedCountriesEndpoint.searchParams.append('app_key', encodeURI(config.appKey));
        }
        return fetch(allowedCountriesEndpoint)
            .then(function (rawResponse) { return rawResponse.json(); })
            .then(function (response) {
            return setCache(__assign(__assign({}, response), { timestamp: Date.now() }));
        });
    }
    function isCacheUpToDate(cache) {
        return (cache != null &&
            cache.timestamp != null &&
            Date.now() - cache.timestamp < maximumCacheAgeHours * 60 * 60 * 1000);
    }
    function getCacheOrFetch() {
        var cache = getCache();
        return isCacheUpToDate(cache)
            ? Promise.resolve(cache)
            : fetchAndCache();
    }
    function clearLocalStorageBuffers() {
        for (var key in localStorage) {
            // The localStorage key name prefixes - 'snowplowOutQueue' - are hardcoded (see // https://github.com/snowplow/snowplow-javascript-tracker/blob/de97c3bef9ef4ceccd001ea051eddbf2a05e2c70/libraries/browser-tracker-core/src/tracker/out_queue.ts#L120)
            // For now we need to cycle through localStorage and clear all the values connected to those prefixes.
            // This will e.g. clear 'snowplowOutQueue_sp_post2' and 'snowplowOutQueue_sp_post2.expires' keys.
            if (key.startsWith('snowplowOutQueue')) {
                localStorage.removeItem(key);
            }
        }
    }
    function enableOptOutCookie() {
        (0, browser_tracker_core_1.cookie)(optOutCookie, 'true', optOutCookieTtlSeconds);
    }
    function disableOptOutCookie() {
        (0, browser_tracker_core_1.deleteCookie)(optOutCookie);
    }
    function disableTracking(tracker) {
        enableOptOutCookie();
        tracker.clearUserData();
        for (var index = 0; index < tracker.sharedState.outQueues.length; index++) {
            tracker.sharedState.outQueues[index] = [];
        }
        clearLocalStorageBuffers();
    }
    function enableTracking(tracker) {
        disableOptOutCookie();
        // Collector endpoint might not be initiated here yet, so we'll put this into next task
        setTimeout(function () {
            tracker.flushBuffer({ newBufferSize: 1 });
        });
    }
    function waitForAllowedCountriesCheck(tracker) {
        waitingForAllowedCountriesCheck = true;
        return getCacheOrFetch()
            .then(function (cache) {
            if (cache.basicstats) {
                enableTracking(tracker);
            }
            else {
                disableTracking(tracker);
            }
        })
            .finally(function () {
            waitingForAllowedCountriesCheck = false;
        });
    }
    function shouldCheckAllowedCountries() {
        return (Date.now() - lastCheckTimestamp > maximumCacheAgeHours * 60 * 60 * 1000);
    }
    return {
        // Consider throttling beforeTrack https://lodash.com/docs#throttle
        // or just use setTimeout in activateBrowserPlugin to check every 3 hours (or slightly less)
        beforeTrack: function () {
            if (!waitingForAllowedCountriesCheck &&
                tracker != null &&
                shouldCheckAllowedCountries()) {
                lastCheckTimestamp = Date.now();
                waitForAllowedCountriesCheck(tracker);
            }
        },
        activateBrowserPlugin: function (browserTracker) {
            tracker = browserTracker;
            // Disable cookie temporarily so we can buffer up events
            // and check if we are allowed to track
            tracker.setBufferSize(Number.POSITIVE_INFINITY);
            tracker.setOptOutCookie(optOutCookie);
            disableOptOutCookie();
            waitForAllowedCountriesCheck(tracker);
        },
    };
}
exports.AllowedCountriesPlugin = AllowedCountriesPlugin;
